<template>
  <button type="button" :class="classList" class="date-picker-button">
    <div class="date-picker-wrapper">
      <i class="icon-calendar userphoto-icon big date-picker"></i>
      <!--<i v-if="showSelected && (start && end)">{{start}} / {{end}}</i>-->
    </div>
  </button>
</template>
<script>
export default {
  name: 'DrPicker',
  data() {
    return {
      options: {
        singleDatePicker: this.isSingle,
        timePicker24Hour: true,
        autoApply: true,
        timePicker: this.hasTimePicker,
        opens: this.side,
        drops: this.drops,
        parentEl: '#map',
        locale: {
          singleDatePicker: true,
          applyLabel: this.$i18n.t('drPicker.apply'),
          fromLabel: this.$i18n.t('drPicker.from'),
          startDate: this.startDate,
          endDate: this.endDate,
          format: 'YYYY-DD-MM',
          toLabel: this.$i18n.t('drPicker.to'),
          cancelLabel: this.$i18n.t('drPicker.cancel'),
          customRangeLabel: this.$i18n.t('drPicker.custom'),
          daysOfWeek: [
            this.$i18n.t('drPicker.sunday_short'),
            this.$i18n.t('drPicker.monday_short'),
            this.$i18n.t('drPicker.tuesday_short'),
            this.$i18n.t('drPicker.wednesday_short'),
            this.$i18n.t('drPicker.thursday_short'),
            this.$i18n.t('drPicker.friday_short'),
            this.$i18n.t('drPicker.saturday_short')
          ],
          monthNames: [
            this.$i18n.t('drPicker.january_short'),
            this.$i18n.t('drPicker.february_short'),
            this.$i18n.t('drPicker.march_short'),
            this.$i18n.t('drPicker.april_short'),
            this.$i18n.t('drPicker.may_short'),
            this.$i18n.t('drPicker.june_short'),
            this.$i18n.t('drPicker.july_short'),
            this.$i18n.t('drPicker.august_short'),
            this.$i18n.t('drPicker.september_short'),
            this.$i18n.t('drPicker.october_short'),
            this.$i18n.t('drPicker.november_short'),
            this.$i18n.t('drPicker.december_short')
          ]
        },
        ranges: {
          [this.$i18n.t('drPicker.last24')]: [moment().subtract(24, 'hours'), moment()],
          [this.$i18n.t('drPicker.week')]: [moment().subtract(6, 'days'), moment()],
          [this.$i18n.t('drPicker.thirty_days')]: [moment().subtract(29, 'days'), moment()]
          // [this.$i18n.t('drPicker.always')]: [moment(), 0]
        }
      },
      start: null,
      startFormatted: null,
      end: null,
      endFormatted: null
    };
  },
  props: {
    showSelected: { type: Boolean, default: false },
    startDate: { type: Number, default: Date.now() },
    endDate: { type: Number, default: Date.now() },
    onChange: { type: Function, required: true },
    classList: { type: String, default: 'btn btn-primary float-right' },
    side: { type: String, default: 'left' },
    drops: { type: String, default: 'down' },
    format: { type: String, default: 'DD-MM-YYYY' },
    isSingle: { type: Boolean, default: false },
    hasTimePicker: { type: Boolean, default: true },
    mountPoint: { type: String, default: '.date-picker-button' }
  },
  methods: {
    //
  },
  computed: {
    //
  },
  mounted() {
    $(`${this.mountPoint}`).daterangepicker(this.options, (start, end, label) => {
      this.start = start;
      this.startFormatted = start.format('DD-MM-YYYY');
      this.end = end;
      this.endFormatted = end.format('DD-MM-YYYY');
      this.onChange({
        start: this.start,
        startFormatted: this.startFormatted,
        end: this.end,
        endFormatted: this.endFormatted
      });
    });
  }
};
</script>
<style lang="scss">
.date-picker-wrapper {
  cursor: pointer;
}
.daterangepicker.daterangepicker {
  z-index: 1000000;
}
</style>
